import React, { Component } from "react";
import smokeTex from "./smoke-1.png";
// import logo from "./logo2.png";
// import { Link as ScrollLink } from "react-scroll";
import { Link } from "react-router-dom";
// import dArrow from "../imgs/dArrow.png";
import * as THREE from "three";
import { shuffle, directions, animations } from 'txt-shuffle'

class ThreeScene extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hex: "0x45C6EE",
      time: 0,
      back1: 0,
      back2: 0.7,
      back3: 0,
      dis1: "hidden",
      dis2: "visible",
      dis3: "hidden",
      exiting: false,
      contentOpacity: 0,
      contentVis: "hidden",
      landerOpacity: 1,
      landerVis: "visible",
    };
    this.handleContent = this.handleContent.bind(this);
    this.video1 = React.createRef();
    this.video2 = React.createRef();
    this.video3 = React.createRef();

    this.start = this.start.bind(this);
    this.stop = this.stop.bind(this);
    this.animate = this.animate.bind(this);
    this.runShuffle = this.runShuffle.bind(this);
    this.shuffleRef = React.createRef();
  }
  componentDidMount() {
    var counter = 0;
    const width = this.mount.clientWidth;
    const height = this.mount.clientHeight;
    const scene = new THREE.Scene();
    scene.fog = new THREE.FogExp2(0x03544e, 0.001);
    const camera = new THREE.PerspectiveCamera(75, width / height, 0.1, 2000);
    counter = 0;
    camera.position.z = 200;
    camera.position.y = 0;
    const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    var stColor = new THREE.Color("hsl(0, 100%, 15%)");
    renderer.setClearColor(0xffffff, 0);
    var orangeLight = new THREE.PointLight(stColor, 50, 450, 2);
    orangeLight.position.set(200, 300, 100);
    scene.add(orangeLight);
    var redLight = new THREE.PointLight(stColor, 50, 450, 2);
    redLight.position.set(-200, 300, 100);
    scene.add(redLight);
    var blueLight = new THREE.PointLight(stColor, 50, 450, 6);
    blueLight.position.set(0, 300, 300);
    scene.add(blueLight);
    var greenLight = new THREE.PointLight(stColor, 50, 450, 2);
    greenLight.position.set(200, 300, -300);
    scene.add(greenLight);
    var whiteLight = new THREE.PointLight(0xffffff, 0, 450, 2);
    whiteLight.position.set(200, 300, -350);
    scene.add(whiteLight);
    var whiteLight2 = new THREE.PointLight(0xffffff, 0, 450, 2);
    whiteLight2.position.set(0, 300, 300);
    scene.add(whiteLight2);
    var cloudParticles = [];
    let loader = new THREE.TextureLoader();
    loader.load(smokeTex, function (texture) {
      var cloudGeo = new THREE.PlaneBufferGeometry(500, 500);
      var cloudMaterial = new THREE.MeshLambertMaterial({
        map: texture,
        transparent: true,
        depthWrite: false,
        size: 0.2,
      });
      for (let p = 0; p < 50; p++) {
        let cloud = new THREE.Mesh(cloudGeo, cloudMaterial);
        cloud.position.set(
          Math.random() * 500 - 250,
          0,
          Math.random() * 600 - 700
        );
        cloud.rotation.x = 0.02;
        cloud.rotation.z = Math.random() * 2 * Math.PI;
        cloud.material.opacity = 0.55;
        cloudParticles.push(cloud);
        scene.add(cloud);
      }
    });
    this.clock = new THREE.Clock();
    this.time = 0;
    this.previousFrameBlinked = false;

    this.counter = counter;
    this.scene = scene;
    this.camera = camera;
    this.cloudParticles = cloudParticles;
    this.renderer = renderer;
    this.blueLight = blueLight;
    this.redLight = redLight;
    this.orangeLight = orangeLight;
    this.greenLight = greenLight;
    this.whiteLight = whiteLight;
    this.whiteLight2 = whiteLight2;
    this.framesSinceBlink = 0;
    this.blinkActive = false;
    this.mount.appendChild(this.renderer.domElement);
    this.start();
    this.runShuffle();
  }
  componentWillUnmount() {
    this.stop();
    this.mount.removeChild(this.renderer.domElement);
  }
  start() {
    if (!this.frameId) {
      this.frameId = requestAnimationFrame(this.animate);
    }
  }
  stop() {
    cancelAnimationFrame(this.frameId);
  }
  resizeCanvasToDisplaySize() {
    const canvas = this.renderer.domElement;
    const width = canvas.clientWidth;
    const height = canvas.clientHeight;
    if (canvas.width !== width || canvas.height !== height) {
      this.renderer.setSize(width, height, false);
      this.camera.aspect = width / height;
      this.camera.updateProjectionMatrix();
    }
  }
  handleContent() {
    this.setState({ contentOpacity: 1, contentVis: "visible" });
  }
  handleLander() {
    this.setState({ landerOpacity: 0, landerVis: "hidden" });
  }

  animate() {
    this.cloudParticles.forEach((p) => {
      p.rotation.z -= 0.004;
    });
    if (this.counter < 9.3) {
      this.counter += 0.1;

      //   this.camera.position.z -=
      //     (-this.counter * this.counter * 0.05 + this.counter) * 0.4;
      //   this.camera.position.y +=
      //     (-this.counter * this.counter * 0.05 + this.counter) * 0.4;

      if (this.counter > 7.05 && this.counter < 7.3) {
        this.handleContent();
      }
    }
    var deltaTime = this.clock.getDelta();
    var blink = Math.floor(this.time / 4) & 1;

    if (this.previousFrameBlinked !== blink) {
      this.whiteLight.intensity = 20;
      this.framesSinceBlink = 0;
      console.log("LIGHTNING");
      this.blinkActive = true;
    }
    if (this.blinkActive) {
      this.framesSinceBlink += 1;
      if (this.framesSinceBlink <= 15) {
        this.whiteLight.intensity += 10;
      }
      if (this.framesSinceBlink > 15 && this.framesSinceBlink < 18) {
        this.whiteLight.intensity -= 10;
        this.whiteLight2.intensity += 10;
      } else if (this.framesSinceBlink === 19) {
        this.whiteLight.intensity = 0;
      } else if (this.framesSinceBlink > 25 && this.framesSinceBlink < 30) {
        this.whiteLight.intensity += 10;
        this.whiteLight2.intensity += 10;
      } else if (this.framesSinceBlink > 30) {
        this.whiteLight.intensity = 0;
        this.whiteLight2.intensity = 0;
        this.blinkActive = false;
      }
    }
    this.previousFrameBlinked = blink;
    this.time += deltaTime;
    // console.log(
    //   this.camera.position.x,
    //   this.camera.position.y,
    //   this.camera.position.z
    // );

    // if (hslFlag) {
    //   hslH += 0.00003;
    //   this.redLight.color.setHSL(hslH, 1, 0.15);
    //   this.blueLight.color.setHSL(hslH, 1, 0.15);
    //   this.orangeLight.color.setHSL(hslH, 1, 0.15);
    //   this.greenLight.color.setHSL(hslH, 1, 0.15);
    //   // this.renderer.setClearColor.color.setHSL(hslH, 0.2, 0.2);
    // }

    // if (!hslFlag) {
    //   hslH -= 0.00003;
    //   this.redLight.color.setHSL(hslH, 1, 0.15);
    //   this.blueLight.color.setHSL(hslH, 1, 0.15);
    //   this.orangeLight.color.setHSL(hslH, 1, 0.15);
    //   this.greenLight.color.setHSL(hslH, 1, 0.15);
    //   // this.renderer.setClearColor.color.setHSL(hslH, 0.2, 0.2);
    // }
    // if(hslH > 0.02 || hslH < 0.001){
    //   hslFlag = !hslFlag;
    // }
    var tanFOV = Math.tan(((Math.PI / 180) * this.camera.fov) / 2);
    var windowHeight = window.innerHeight;
    this.camera.aspect = window.innerWidth / window.innerHeight;
    this.camera.fov =
      (360 / Math.PI) * Math.atan(tanFOV * (window.innerHeight / windowHeight));
    this.camera.updateProjectionMatrix();
    this.resizeCanvasToDisplaySize();

    // this.renderer.setSize(window.innerWidth - 18, window.innerHeight);
    this.renderScene();
    this.frameId = window.requestAnimationFrame(this.animate);
  }

  renderScene() {
    this.renderer.render(this.scene, this.camera);
  }


  runShuffle() {
    const text = 'audaces fortuna juvat';
    const duration = 2;
    const fps = 20;
    const animation = animations.STAY;
    const direction = directions.RANDOM;
    const nglyphs = ''
    const glyphs = nglyphs.length ? nglyphs : undefined;

    const onUpdate = (output) => {
      console.log(output, this.shuffleRef.current.innerText)
      this.shuffleRef.current.innerText = output
    };
    shuffle({ text, duration, fps, glyphs, direction, animation, onUpdate });
  }
  render() {
    return (
      <div
        className="three"
        ref={(mount) => {
          this.mount = mount;
        }}
      >

        <div
          className="centerContent transitionTimeLong"
          style={{
            opacity: this.state.contentOpacity,
            visibility: this.state.contentVis,
          }}
        >
          <div className="titlePage centerHorizontal">
          <p className="landingSubTextAlt2 textGaramond" >
                ChicagoDAO
              </p>
            <div
              className="menuAlt"
              style={{
                opacity: this.state.contentOpacity,
                visibility: this.state.contentVis,
              }}
            >
          
         
              {/* <div className="titleWidth" >
              <p className="landingSubTextAlt textGaramond" ref={this.shuffleRef} >
                .
              </p>
            </div> */}
              <div className="innerMenu">
                
         
               
                <div className="menuBox">
                  <a href="https://discord.gg/aW8Na2mm8z" target="_blank" rel="noopener noreferrer" className="menuText noDecoration">
                    DISCORD
                  </a>
                </div>
                <div className="menuBox">
                  <a href="https://x.com/chicagodao_io" target="_blank" rel="noopener noreferrer" className="menuText noDecoration">
                    TWITTER
                  </a>
                </div>
                {/* 
                <div className="menuBox">
                  <a href="https://governance.chicagodao.io" target="_blank" rel="noopener noreferrer" className="menuText noDecoration">
                    GOVERNANCE
                  </a>
                  <div className="menuBox">
                  <Link to="/auth" className="menuText noDecoration">
                    TALENT
                  </Link>
                   <div className="menuBox">
                  <a href="https://research.chicagodao.io" target="_blank" rel="noopener noreferrer" className="menuText noDecoration">
                    RESEARCH
                  </a>
                </div>
                </div>
                </div><div className="menuBox">
                  <ScrollLink
                    to="footer"
                    smooth={true}
                    duration={500}
                    className="menuText"
                  >
                    CONTACT
                  </ScrollLink>
                </div> */}
              </div>
            </div>
            {/* <div className="landingLogos">
              <img src={logos} alt="logos" className="image" />
            </div> */}
            {/* <div className="titleWidth" >
              <p className="landingSubTextAlt textGaramond" ref={this.shuffleRef} >
                .
              </p>
            </div> */}
            {/* <div className="landingLogo">
              <img src={logo} alt="logo" className="image mainPulse" />
            </div> */}
          </div>
        </div>

        {/* <div
          className="landingDownArrow transitionTimeLong"
          style={{
            opacity: this.state.contentOpacity,
            visibility: this.state.contentVis,
          }}
        >
          <ScrollLink
            className="genLink"
            to="footer"
            smooth={true}
            duration={500}
          >
            <img src={dArrow} alt="dArrow" className="image mainPulse" />
          </ScrollLink>
        </div> */}
        {/* <div
          className="titlePageBottomLeft transitionTimeLong"
          style={{
            opacity: th
            
            
            is.state.contentOpacity,
            visibility: this.state.contentVis,
          }}
        >
          <h1 className="landingMainText ">Welcome to chicagoDAO</h1>
          <p className="landingSubText noMargin">
            We empower the UChicago community
          </p>
        </div> */}
      </div>
    );
  }
}

export default ThreeScene;
