import React, { useState } from "react";
// import { CSSTransition, TransitionGroup } from "react-transition-group";
// import picturesWLogo from "../imgs/picturesWLogo.png";
// import a16z from "../imgs/sponsors/a16z.png";
import { Link } from "react-router-dom";
import { Element } from "react-scroll";
// import initiatives from "./initiatives.json";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import ThreeScene from "./ThreeScene";
import "../styling/index.css";
import "../styling/footer.css";


const url =
  "https://chicagodao.us8.list-manage.com/subscribe/post?u=b797befcc8acc7c414d035f95&amp;id=501a497f5c";

const CustomForm = ({ status, message, onValidated }) => {
  let email;
  const submit = () =>
    email &&
    email.value.indexOf("@") > -1 &&
    onValidated({
      EMAIL: email.value,
    });

  return (
    <div className="mailingListDiv">
      <input
        ref={(node) => (email = node)}
        type="email"
        placeholder="Your email"
        className="input"
      />
      <button className="button" onClick={submit}>
        Submit
      </button>
      <br />
      {status === "sending" && (
        <i
          style={{
            color: "white",
            transition: "all 1s",
            fontSize: "13px",
            opacity: "0.7",
            marginBottom: "8px",
            wordBreak: " break-word",
          }}
        >
          sending...
        </i>
      )}
      {(status === "error" || status === "success") && (
        <i
          style={{
            color: "white",
            transition: "all 1s",
            fontSize: "13px",
            opacity: "0.7",
            marginBottom: "8px",
            wordBreak: " break-word",
          }}
        >
          {message}
        </i>
      )}
    </div>
  );
};

function Home() {
  const [popup, setPopup] = useState(false);
  // const [initiativeSelect, setInitiativeSelect] = useState(
  //   "Current_Initiatives"
  // );

  return (
    <div className="main">
      <div
        className="popupFront"
        style={{
          display: popup ? "block" : "none",
          opacity: popup ? 1 : 0,
        }}
      >
        <div className="innerPopupFront">
          <div className="closePopupFront" onClick={() => setPopup(false)}>
            <span className="hover closePopupTextFront">✕</span>
          </div>
          <p>Join our Mailing List!</p>
          <MailchimpSubscribe
            url={url}
            render={({ subscribe, status, message }) => (
              <CustomForm
                status={status}
                message={message}
                onValidated={(formData) => subscribe(formData)}
              />
            )}
          />
        </div>
      </div>
      <div className="relative">
        <ThreeScene />
      </div>

      <Element name="footer">
        <footer>
          <div className="row footerInner whiteText warpReverse textGaramondBold">
            <div className="biCol lCol">

              {/* <a
                target="_blank"
                rel="noreferrer noopener"
                href="https://forms.gle/zUe8qVDg7f7uPQxW9"
                className="noMargin whiteLink"
              >
                <span className="whiteLinkBorder smallishText noMargin">
                  Interest Form
                </span>
              </a>
              <Link to="/auth" className="noMargin whiteLink noMargin">
                <span className="whiteLinkBorder smallishText">
                  Talent Network
                </span>
              </Link> */}
              <br/><br/>
              <a
                target="_blank"
                rel="noreferrer noopener"
                href="http://blockchain.rso.uchicago.edu"
                className="noMargin whiteLink"
              >
                <span className="whiteLinkBorder smallishText noMargin">
                  Blockchain Chicago (Undergraduate Blockchain Club)
                </span>
              </a>
              {/* <a
                target="_blank"
                rel="noreferrer noopener"
                href="http://boothcha.in"
                className="noMargin whiteLink"
              >
                <span className="whiteLinkBorder smallishText noMargin">
                  Boothchain (Booth's Blockchain Club)
                </span>
              </a> */}
            </div>
            <div className="biCol rCol centerCol">
              <br />
              <br />
              <p className="noMargin genText">coreteam@chicagodao.io</p>
            </div>
          </div>
        </footer>
      </Element>
    </div>
  );
}

export default Home;
